<template>
    <!--创建预约-->
    <el-dialog title="预约信息" :visible.sync="dialogVisible" width="40%" class="dialog" append-to-body @close="closeDialog">
		<el-form ref="form" :model="form" label-width="80px">
		  <el-form-item label="身份证号" required>
		    <el-input v-model="form.idCard" placeholder="请输入身份证号"></el-input>
		  </el-form-item>
		  <el-form-item label="姓名" required>
		    <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
		  </el-form-item>
		  <el-form-item label="手机号码" required>
		    <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
		  </el-form-item>
		  <el-form-item label="预约人数" required v-if="!!flag">
		    <el-input v-model="form.num" placeholder="请输入预约人数"></el-input>
		  </el-form-item>
		</el-form>
		<!-- <div class="item" v-if="num!=1">
		    <div>
		        <span class="title">预约人数：</span>
		    </div>
			<input type="number" placeholder="请输入" placeholder-class="placeholder" v-model="num" />
		</div> -->
		<span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="submit">确 定</el-button>
		</span>
    </el-dialog>
</template>

<script>
	import { $isChineseName,$isCard,$regPhone } from '../../utils/utils.js'
export default {
	name:"bookingInfo",
	props:['ruleVisible','flag','maxNum'],
    data() {
        return {
			dialogVisible: this.ruleVisible,
            idCard: '',
            name: '',
			phone: '',
			num: 1,
			form: {
				idCard: '',
				name: '',
				phone: '',
				num: 1
			}
        };
    },
	watch: {
		ruleVisible(val) {
			this.dialogVisible = val;
		},
		dialogVisible(val) {
			this.$emit("update:ruleVisible",val);
		}
	},
    methods: {
        submit() {
            if (!$isChineseName(this.form.name)) {
                this.$message.error('请输入正确的姓名');
                return;
            }
            if (!$isCard(this.form.idCard)) {
                this.$message.error('请输入正确的身份证号');
                return;
            }
			if (!$regPhone(this.form.phone)) {
			    this.$message.error('请输入正确的手机号码');
			    return;
			}
			if(isNaN(this.form.num)){
				this.$message.error('请输入正确的预约人数');
				return;
			}
			if(this.maxNum<this.form.num){
				this.$message.error('可预约人数已不足'+this.form.num+'人');
				return;
			}
            let data = {
                reservationIdNumber: this.form.idCard,
                reservationName: this.form.name,
                reservationPhoneNumber: this.form.phone,
				num: this.form.num
            };
			this.closeDialog();
            this.$emit('booking', data);
        },
		closeDialog(){
			this.form = {
				idCard: '',
				name: '',
				phone: '',
				num: 1
			};
		}
    },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
    padding: 24px 24px 12px 24px;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
}
</style>
