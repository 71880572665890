<template>
	<div>
		<div class="list1">
			<div class="item" v-for="(item, index) in list" :key="index" style="position: relative;">
				<div style="line-height: 24px;margin-bottom: 10px;">
					<div>{{ item.name }}</div>
					<div><span>数量：</span>{{ item.quantity||0 }}</div>
					<div><span>使用总时长：</span>{{ Math.round(item.useDuration)+'个小时' }}</div>
				</div>
				<div class="img">
					<div class="" v-for="(t, i) in item.picUrl" :key="i" @click.stop="() => {}">
						<el-image style="width: 100%; height: 130px" :src="t"
							:preview-src-list="item.picUrl"></el-image>
					</div>
				</div>
				<el-button type="primary" @click="openRecord(item.id)" style="position: absolute;top: 40px;right: 100px;">使用记录</el-button>
			</div>
		</div>
		<el-pagination @size-change="getEquipmentList" @current-change="getEquipmentList" :current-page.sync="page"
			:page-sizes="[12, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next, sizes" class="pagination"
			:total="total" background></el-pagination>
		<el-dialog title="使用记录" :visible.sync="dialogVisible" width="50%">
			<el-table v-loading="loading" :data="recordList">
				<el-table-column label="用户" align="center" prop="reservationName">
					<template slot-scope="scope">
						<img :src="scope.row.avatar" v-if="scope.row.avatar"
							style="width: 80px;height: 80px;border-radius: 50%;vertical-align: middle;" />
						{{scope.row.reservationName}}
					</template>
				</el-table-column>
				<el-table-column label="开始时间" align="center" prop="startTime" width="180">
					<template slot-scope="scope">
						<span>{{ scope.row.useDate + ' ' + scope.row.startTime }}</span>
					</template>
				</el-table-column>
				<el-table-column label="结束时间" align="center" prop="endTime" width="180">
					<template slot-scope="scope">
						<span>{{ scope.row.useDate + ' ' + scope.row.endTime }}</span>
					</template>
				</el-table-column>
				<el-table-column label="使用时长(小时)" align="center" prop="useDuration" />
			</el-table>
			<el-pagination :hide-on-single-page="true" background layout="prev, pager, next"
				:page-size.sync="queryParams.pageSize" :current-page.sync="queryParams.pageNum"
				@current-change="getUseRecordList" :total="recordTotal">
			</el-pagination>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: "equipmentList",
		props: ['id'],
		data() {
			return {
				limit: 12,
				page: 1,
				total: 0,
				list: [],
				dialogVisible: false,
				recordList: [],
				queryParams: {
					pageNum: 1,
					pageSize: 10,
					equipmentId: null
				},
				recordTotal: 0,
				loading: false
			}
		},
		created() {
			this.getEquipmentList();
		},
		watch: {
			id(val) {
				this.getEquipmentList();
			}
		},
		methods: {
			getEquipmentList() {
				let data = {
					pageNum: this.page,
					pageSize: this.limit,
					libraryId: this.id
				};
				this.$hao.getEquipmentList(data).then((res) => {
					let arr = res.rows.map((item) => {
						item.picUrl = item.picUrl ? item.picUrl.split(',') : [];
						return item;
					});
					this.list = arr;
					this.total = res.total;
				});
			},
			openRecord(id) {
				this.dialogVisible = true;
				this.getUseRecordList(id);
			},
			getUseRecordList(id) {
				this.loading = true;
				this.queryParams.equipmentId = id;
				this.$hao.getEquipmentRecordList(this.queryParams).then(res => {
					this.recordList = res.rows;
					this.recordTotal = res.total;
					this.loading = false;
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.list1 {
		// margin-top: 24px;

		.item {
			display: flex;
			flex-direction: column;
			page-break-inside: avoid;
			width: 100%;
			background: #ffffff;
			border: 1px solid #ebebeb;
			border-radius: 4px;
			margin-bottom: 12px;
			padding: 24px;

			.top {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;

				.left {
					display: flex;
					align-items: center;

					img {
						width: 32px;
						height: 32px;
						border-radius: 50%;
						margin-right: 8px;
					}

					span {
						margin-right: 8px;
						font-weight: 400;
						font-size: 13px;
						color: #333333;
						flex: none;
						order: 0;
						flex-grow: 0;
					}

					span:last-child {
						color: #999999;
					}
				}

				.right {
					display: flex;
					align-items: center;

					.items {
						display: flex;
						align-items: center;
						margin-left: 24px;

						img {
							width: 18px;
							height: 18px;
							margin-right: 4px;
						}

						span {
							font-size: 14px;
							line-height: 20px;
							color: #797a87;
						}
					}
				}
			}

			.content {
				padding: 12px 0;
				font-size: 14px;
				line-height: 17px;
				color: #333333;
			}

			.img {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				grid-column-gap: 4px;
				width: 50%;

				img {
					width: 100%;
					height: 100px;
					margin-top: 4px;
				}
			}
		}
	}
</style>