<template>
	<div>
		<div class="list1">
		    <div class="item" v-for="(item, index) in list" :key="index">
		        <div class="top">
		            <div class="left">
		                <span>{{ item.nickName }}</span>
		                <span>{{ item.createTime }}</span>
		            </div>
		            <div class="right">
		            </div>
		        </div>
		        <div class="content">{{ item.content||'' }}</div>
		        <div class="img">
		            <div class="" v-for="(t, i) in item.picUrl" :key="i" @click.stop="() => {}">
		                <el-image style="width: 100%; height: 130px" :src="t" :preview-src-list="item.picUrl"></el-image>
		            </div>
					<div v-if="item.videoUrl">
						<video controls :src="item.videoUrl" style="width: 100%; height: 130px"></video>
					</div>
		        </div>
		    </div>
		</div>
		<el-pagination @size-change="getEvaluateList" @current-change="getEvaluateList" :current-page.sync="page" :page-sizes="[12, 20, 30, 50]" :page-size.sync="limit" layout="prev, pager, next, sizes" class="pagination" :total="total" background></el-pagination>
	</div>
</template>

<script>
	export default {
		name: "evaluateList",
		props:['id','type'],
		data(){
			return {
				limit: 12,
				page: 1,
				total: 0,
				list: [],
			}
		},
		created(){
			this.getEvaluateList();
		},
		watch: {
			id(val){
				this.getEvaluateList();
			}
		},
		methods: {
			getEvaluateList(){
				let data = {
				    pageNum: this.page,
				    pageSize: this.limit,
				    originalRelevanceId: this.id,
					type: this.type
				};
				this.$hao.listEvaluation(data).then((res) => {
				    let arr = res.rows.map((item) => {
				        item.picUrl = item.picUrl?item.picUrl.split(','):[];
				        return item;
				    });
				    this.list = arr;
				    this.total = res.total;
				});
			}
		}
	}
</script>

<style scoped lang="scss">
	.list1 {
	    // margin-top: 24px;
	    .item {
	        display: flex;
	        flex-direction: column;
	        page-break-inside: avoid;
	        width: 100%;
	        background: #ffffff;
	        border: 1px solid #ebebeb;
	        border-radius: 4px;
	        margin-bottom: 12px;
	        padding: 24px;
	
	        .top {
	            display: flex;
	            align-items: flex-start;
	            justify-content: space-between;
	
	            .left {
	                display: flex;
	                align-items: center;
	
	                img {
	                    width: 32px;
	                    height: 32px;
	                    border-radius: 50%;
	                    margin-right: 8px;
	                }
	
	                span {
	                    margin-right: 8px;
	                    font-weight: 400;
	                    font-size: 13px;
	                    color: #333333;
	                    flex: none;
	                    order: 0;
	                    flex-grow: 0;
	                }
	
	                span:last-child {
	                    color: #999999;
	                }
	            }
	
	            .right {
	                display: flex;
	                align-items: center;
	
	                .items {
	                    display: flex;
	                    align-items: center;
	                    margin-left: 24px;
	
	                    img {
	                        width: 18px;
	                        height: 18px;
	                        margin-right: 4px;
	                    }
	
	                    span {
	                        font-size: 14px;
	                        line-height: 20px;
	                        color: #797a87;
	                    }
	                }
	            }
	        }
	
	        .content {
	            padding: 12px 0;
	            font-size: 14px;
	            line-height: 17px;
	            color: #333333;
	        }
	
	        .img {
	            display: grid;
	            grid-template-columns: repeat(3, 1fr);
	            grid-column-gap: 4px;
	            width: 50%;
	            img {
	                width: 100%;
	                height: 100px;
	                margin-top: 4px;
	            }
	        }
	    }
	}
</style>