<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="top">
            <img class="left" :src="data.picUrl" alt="" />
            <div class="right">
                <div>
                    <div class="title">{{ data.libName }}</div>
                    <div class="item">
                        <img src="@/assets/images/Frame-24.png" alt="" />
                        <span>占地面积：{{ data.usableArea || 0 }}平</span>
                    </div>
                    <div class="item" v-if="data.address">
                        <img src="@/assets/images/Frame-25.png" alt="" />
                        <span>{{ data.address }}</span>
                    </div>
                    <div class="item">
                        <img src="@/assets/images/Frame-26.png" alt="" />
                        <span>可容纳人数：{{ data.capacityNum || 0 }}人</span>
                    </div>
                    <!-- <div class="item">
                        <img src="@/assets/images/Frame-27.png" alt="" />
                        <span>场馆设备：{{ data.siteEquipment || '未知' }}</span>
                    </div> -->
                    <div class="row">
                        <span>选择日期：</span>
						<template v-if="librarySessionList.length>0">
							<el-date-picker v-model="calendarValue" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
						</template>
						<span v-else>预约未开放</span>
					</div>
                    <div class="row">
                        <span>选择场次：</span>
						<template v-if="librarySessionList.length>0">
							<el-select @change="selectChange" v-model="librarySessionId" placeholder="请选择">
								<el-option v-for="item in librarySessionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</template>
                        <span v-else>预约未开放</span>
                    </div>
                </div>
				<div class="butts">
					<div class="butt" v-if="selectList[selectIndex] && selectList[selectIndex].bookingId" @click="getBookingDetail(selectList[selectIndex].bookingId)">预约详情</div>
					<div class="butt" v-if="selectList.length > 0 && !!selectList[selectIndex].bookingId" @click="addBooking">取消预约</div>
					<div class="butt" v-if="selectList.length > 0 && !selectList[selectIndex].bookingId" @click="openInfoDialog">立即预约</div>
				</div>
            </div>
        </div>
		<el-tabs v-model="activeName" type="card" class="lib-tab">
			<el-tab-pane label="场馆介绍" name="first">
				<div class="titles">场馆介绍</div>
				<div class="details">{{ data.description }}</div>
				<div class="titles">温馨提示</div>
				<div class="details">{{ data.remark }}</div>
			</el-tab-pane>
			<el-tab-pane label="预约评价" name="second">
				<evaludate-list :id="id" type="0"></evaludate-list>
			</el-tab-pane>
			<el-tab-pane label="场馆设备" name="third" v-if="equipmentNum>0">
				<equipment-list :id="id"></equipment-list>
			</el-tab-pane>
		</el-tabs>
        <el-dialog title="预约成功" :visible.sync="dialogVisible" class="book-dialog" width="30%" append-to-body>
            <div class="code">验证码 {{ qrcode.qrCode }}</div>
            <img :src="qrcode.picUrl" alt="" />
            <div>{{ qrcode.libraryName }}</div>
            <div>预约时间：{{ qrcode.bookingTime }}</div>
            <div class="line"></div>
            <div>预约信息</div>
            <div>预约人：{{ qrcode.nickName }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">关 闭</el-button>
            </span>
        </el-dialog>
		<booking-info :ruleVisible.sync="infoDialogVisible" @booking="addBooking" :max-num="maxNum" :flag="flag"></booking-info>
    </div>
</template>
<script>
	import bookingInfo from './bookingInfo.vue'
	import evaludateList from './evaluateList.vue'
	import equipmentList from './equipmentList.vue'
	import { parseTime } from '../../utils/utils.js';
export default {
	components:{bookingInfo,evaludateList,equipmentList},
    data() {
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '活动',
                },
                {
                    title: '场馆预约',
                    to: { path: '/venueReservation' },
                },
                {
                    title: '去预约',
                },
            ],
            librarySessionList: [],
            id: 0,
            data: {},
            calendarValue: new Date().getFullYear() + '-' + (Number(new Date().getMonth()) + 1) + '-' + new Date().getDate(), // 日历选择
            librarySessionId: 0,
            dialogVisible: false,
            selectList: [],
            selectIndex: 0,
            qrcode: {},
			infoDialogVisible: false,
			activeName: 'first',
			equipmentNum: 0,
			restList: []
        };
    },
    watch: {
        calendarValue: function () {
            this.selectList = [];
            this.librarySessionId = 0;
            this.getLibrarySessionList();
        },
    },
	computed:{
		maxNum(){
			if(this.selectList[this.selectIndex]){
				return (this.data.capacityNum||0)-this.selectList[this.selectIndex].bookingNum;
			} else {
				return 0;
			}
		},
		flag(){
			return this.data.label==1;
		}
	},
    created() {
        this.id = this.$route.query.id;
        this.getData();
        // this.getSessionList();
    },
    methods: {
        dialogVisibleClose() {
            this.dialogVisible = false;
            setTimeout(() => {
                this.$router.go(0);
            }, 1000);
        },
        getData() {
            this.$hao.getLibraryDetail(this.id).then((res) => {
                this.data = res.data;
				if(this.$route.query.label != null && this.$route.query.label != undefined){
					this.data.label = this.$route.query.label;
				}
				this.$hao.listLibraryRest({pageNum:1,pageSize:1000,libraryId:this.id}).then(response=>{
					this.restList = response.rows.map(ele=>ele.restTime);
					this.getLibrarySessionList();
				})
            });
			this.$hao.getEquipmentList({pageNum:1,pageSize:1,libraryId: this.id}).then((res) => {
			    this.equipmentNum = res.total;
			});
        },
        selectChange(e) {
            console.log(e);
            this.selectIndex = e;
        },
        getLibrarySessionList() {
			if(this.restList.includes(this.calendarValue)){
				this.librarySessionList = [];
				this.selectList = [];
				this.librarySessionId = '';
				return;
			}
            this.$hao.getSessionList({ libraryId: this.id, bookingTime: this.calendarValue, libType: this.data.libType }).then((res) => {
                let arr = res.rows.map((item, index) => {
                    let num = this.data.capacityNum - item.bookingNum;
                    console.log(num);
                    return {
                        value: index,
                        // label: item.sessionName + '（剩余' + num + '个座位）',
                        label: this.splitTime(item.startTime) + '-' + this.splitTime(item.endTime) + (item.bookingId != null ? '(已预约)' : '(' + (num > 0 ? '可' : '不可') + '预约，剩余' + num + ')'),
                        disabled: item.bookingId ? true : num <= 0 ? true : false,
                    };
                });
                console.log(arr);
                this.librarySessionList = arr;
                this.selectList = res.rows;
				if ( this.selectList.length>0 ) {
					this.librarySessionId = this.selectIndex;
				}
            });
        },		
		getBookingDetail(id) {
			this.$hao.getBookingDetail(id).then(res => {
				this.qrcode = res.data;
				this.dialogVisible = true;
			});
		},
		openInfoDialog(){
			let obj = this.selectList[this.selectIndex];
			if(parseTime(this.calendarValue, '{y}-{m}-{d}')<parseTime(new Date(), '{y}-{m}-{d}')){
				this.$message.error(parseTime(this.calendarValue, '{y}-{m}-{d}')+"预约已结束")
				return;
			}
			if(parseTime(this.calendarValue, '{y}-{m}-{d}')==parseTime(new Date(), '{y}-{m}-{d}')
			&& obj.endTime<parseTime(new Date(), '{h}:{i}:{s}')){
				this.$message.error("该时段预约已结束")
				return;
			}
			this.infoDialogVisible = true;
		},
        addBooking(info={}) {
			this.infoDialogVisible = false;
            let obj = this.selectList[this.selectIndex];
            if (obj.bookingId === null) {
                this.$hao.addBookingSession({ libraryId: this.data.id, bookingTime: this.calendarValue, librarySessionId: obj.id, ...info }).then((res) => {
                    if(res.code==200){
						this.selectList[this.selectIndex].isBooking = res.id;
						this.$message.success('预约成功');
						this.getBookingDetail(res.id);
						this.getLibrarySessionList();
					}
                });
            } else {
				if(obj.bookingStatus>=5){
					this.$message.success('预约已核销');
					return;
				}
                this.$hao.delBookingSession(obj.bookingId).then((res) => {
                    this.selectList[this.selectIndex].isBooking = null;
                    this.$message.success('取消预约成功');
					this.getLibrarySessionList();
                    // setTimeout(() => {
                    //     this.$router.go(0);
                    // }, 1000);
                });
            }
        },
        splitTime(time) {
            return time.split(':')[0] + ':' + time.split(':')[1];
        },
    },
};
</script>
<style scoped lang="less">
.row {
    display: flex;
    margin-top: 16px;
    align-items: center;
}
::v-deep .el-select {
    width: 300px;
}
.book-dialog ::v-deep .el-dialog__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    div:nth-child(1) {
        padding: 4px 12px;
        font-size: 13px;
        color: #f33f3e;
    }
    img {
        width: 120px;
        height: 120px;
        margin: 10px 0;
    }
    div:nth-child(3) {
        font-size: 13px;
        color: #333333;
    }
    div:nth-child(4),
    div:nth-child(8) {
        font-weight: 700;
        font-size: 14px;
        color: #333333;
        margin: 10px 0;
    }
    div:nth-child(5),
    div:nth-child(6),
    div:nth-child(9) {
        font-size: 13px;
        color: #666666;
        margin-bottom: 10px;
    }
    .line {
        height: 1px;
        width: 100%;
        background: #ebebeb;
    }
}
.top {
    display: flex;
    .left {
        width: 580px;
        height: 385px;
        border-radius: 10px;
    }
    .right {
        flex: 1;
        margin-left: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
            font-weight: 700;
            font-size: 20px;
            color: #333333;
            margin-bottom: 8px;
        }
        .tips {
            display: flex;
            margin: 24px 0 36px 0;
            .tip {
                background: #f5f5f5;
                padding: 2px 12px;
                margin-right: 8px;
                font-size: 13px;
                line-height: 19px;
                color: #f33f3e;
                flex: none;
                flex-grow: 0;
                background: rgba(243, 63, 62, 0.1);
                border-radius: 2px;
                order: 1;
            }
        }
        .item {
            display: flex;
            align-items: center;
            margin-top: 16px;
            img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
            span {
                font-size: 16px;
                color: #333333;
                flex: none;
                order: 1;
                flex-grow: 0;
            }
        }
        .butts {
            display: flex;
            align-items: flex-end;
            .butt {
                padding: 12px 80px;
                background: #eda200;
                box-shadow: 0 2px 4px rgba(14, 79, 71, 0.2);
                border-radius: 4px;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #ffffff;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin-top: 37px;
                width: max-content;
				margin-right: 10px;
            }
            .butt1 {
                display: flex;
                flex-direction: column;
                margin-left: 24px;
                align-items: center;
                img {
                    width: 20px;
                    height: 20px;
                    margin-bottom: 2px;
                }
                span {
                    font-size: 12px;
                    line-height: 17px;
                    color: #666666;
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }
        }
    }
}
.titles {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #333333;
    margin: 24px 0 12px 0;
}
.details {
    font-size: 16px;
    line-height: 23px;
    color: #666666;
}
.details:last-child {
    margin-bottom: 150px;
}

.lib-tab {
	margin-top: 20px;
	
	/deep/.el-tabs__item {
		font-size: 16px;
		font-weight: 700;
	}
}
</style>
